<template>
  <section
    v-if="product"
    id="livre_papier_overview"
    class="grid-zone"
  >
    <div id="block_left">
      <figure id="illustration">
        <img
          :src="product.url_couverture
            || require(`@lde/core_lde_vue/dist/assets/media/missing_img/no_img_${noImgName}.svg`)"
          :alt="$t('general.alt-image-produit')"
          @error="product.url_couverture = null"
        />
        <Badge
          v-if="product.nouveaute"
          type="new"
        />
      </figure>
      <br />
      <ButtonClassic
        balise-type="a"
        variant="ghost"
        :label="$t('general.feuilleter')"
        color="primary"
        icon="right"
        size="large"
        :ext-link="product.url_demonstration"
        :disabled="!product.url_demonstration"
      >
        <template #right-icon>
          <UilEye />
        </template>
      </ButtonClassic>
    </div>
    <div id="block_right">
      <h1
        id="product_title"
        class="s3 bold"
      >
        {{ product.libelle }}
      </h1>
      <p
        v-if="product.auteurs.length"
        id="auteurs"
        class="s4"
      >
        {{ product.auteurs.length > 3
          ? $t("livres-manuels-papier.collectif")
          : product.auteurs.map((a) => `${a.fullname}`).join(", ") }}
      </p>
      <p
        id="disponibilite"
        class="text-regular"
      >
        <span id="ean">{{ $t('produit.ean') }} {{ product.ean }}</span>
        <span
          class="circle"
          :class="couleurDispo"
        />
        <span :class="`text-${couleurDispo}`">
          {{ libelleDispo }}
        </span>
      </p>
      <div
        v-if="product.offre_defaut"
        id="choice_add_to_list"
      >
        <header class="text-small italic">
          <p>{{ $t("produit.prix-ttc-titre-indicatif") }}</p>
        </header>
        <div id="block_offer">
          <div class="prices text-medium">
            <strong
              v-if="product.offre_defaut.prix_editeur !== product.offre_defaut.prix_ht"
              class="all-taxes"
              v-html="$t('prix.ttc', { prix: moneyFilter(product.offre_defaut.prix_editeur), })"
            />
            <span
              class="duty-free"
              v-html="$t('prix.ht', { prix: moneyFilter(product.offre_defaut.prix_ht), })"
            />
          </div>
          <InputQuantity
            v-model="chosenQuantity"
            :status="product.offre_defaut.quantite < product.offre_defaut.quantite_min ? 'warning' : 'default'"
            :disabled="!computedIsProductAvailable"
            :min="1"
          />
        </div>
      </div>

      <div
        v-if="computedIsProductAvailable"
        id="zone_select_button"
      >
        <p class="text-medium bold">
          {{ $t("general.ajouter-produit") }}
        </p>
        <div class="zone-liste-panier">
          <ButtonClassic
            v-if="computedIsProductAvailable"
            variant="special"
            :label="$t('liste.a-une-liste')"
            color="primary"
            icon="left"
            size="medium"
            :disabled="addToListDisabled || isImpersonating || !chosenQuantity || isFetchingActiveLists"
            @click="!addToListDisabled ?
              $emit('add-to-liste', { id: product.offre_defaut.id, quantite: chosenQuantity, })
              : ''
            "
          >
            <template #left-icon>
              <InfiniteLoader v-if="isFetchingActiveLists" />
              <UilBookmark v-else />
            </template>
          </ButtonClassic>
          <ButtonClassic
            v-if="computedIsProductAvailable"
            variant="solid"
            :label="$t('panier.au-panier')"
            color="secondary"
            icon="right"
            size="large"
            :disabled="addToListDisabled || isImpersonating || !chosenQuantity"
            @click="!addToListDisabled ?
              $store.dispatch(
                'addOffersToPanier',
                { lignes: [{ id_offre: product.offre_defaut.id, quantite: chosenQuantity, }, ], })
              : ''
            "
          >
            <template #right-icon>
              <IconPanier />
            </template>
          </ButtonClassic>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  ButtonClassic,
  InputQuantity,
  moneyFilter,
  InfiniteLoader,
} from "@lde/core_lde_vue";
import Badge from "@/components/Badge.vue";

import { UilEye, UilBookmark } from "@iconscout/vue-unicons";
import IconPanier from "@/components/icons/IconPanier.vue";

import mixinDisponibilite from "@/mixins/mixinDisponibilite";

import { mapGetters } from "vuex";

/**
 * Affiche les informations de base du produit avec les licences disponibles.
 */
export default {
  name: "LivrePapierOverview",
  components: {
    ButtonClassic,
    InputQuantity,
    Badge,
    UilEye,
    UilBookmark,
    IconPanier,
    InfiniteLoader,
  },
  mixins: [mixinDisponibilite],
  props: {
    /**
     * Produit affiché.
     */
    product: {
      type: Object,
      required: true,
    },
  },
  emits: [
    /**
     * Déclenché au clic sur le bouton d'ajout à la liste.
     * @param {Object} offer Offre associée à la quantité.
     */
    "add-to-liste",
  ],
  data() {
    return {
      chosenQuantity: 1,
    };
  },
  computed: {
    ...mapGetters(["hasPerm", "compatibleAdoptant", "user", "isFetchingActiveLists"]),
    noImgName() {
      let type = "";

      if (this.product.offre_defaut?.manuel_numerique) {
        type = "numerique";
      } else if (this.product.offre_defaut?.livre_papier) {
        type = "papier";
      } else if (this.product.offre_defaut?.fourniture) {
        type = "fourniture";
      } else {
        type = "image";
      }

      return type;
    },
    addToListDisabled() {
      return !this.hasPerm("add_lignedeliste")
        || this.product.offre_defaut?.prescripteur
        || !this.computedIsProductAvailable;
    },
  },
  methods: {
    /**
     * Change la quantité.
     * @param {Object} ligne Information de la ligne.
     * @param {Number} val Nouvelle quantité.
     */
    changeQuantity(offer, val) {
      this.$set(offer, "quantite", val);
    },
    moneyFilter,
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/livres_papier/livre_papier_overview.scss";

#livre_papier_overview {
  #choice_add_to_list header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
