<template>
  <PageContent
    class="fiche-livre-papier"
    :nav-titles="navTitles"
    :prev-page="prevPage"
  >
    <template
      v-if="product"
      #header-right
    >
      <ButtonClassic
        v-if="product.manuels_numeriques && product.manuels_numeriques.length"
        balise-type="router-link"
        :label="$t('produit.version-numerique')"
        variant="ghost"
        color="primary"
        icon="right"
        :router-link-obj="{
          name: 'catalogues_ressources_numeriques_item', params: {id: product.manuels_numeriques[0].id, },
        }"
      >
        <template #right-icon>
          <IconNumeriqueAlt />
        </template>
      </ButtonClassic>
      <Badge
        v-if="badgeDispoInfo.variant && !computedIsProductAvailable"
        id="header_badge"
        :variant="badgeDispoInfo.variant"
        :title="badgeDispoInfo.variant !== 'remplace' ? badgeDispoInfo.title : null"
        medium
      />
      <ButtonGroup>
        <ButtonClassic
          variant="solid"
          icon="right"
          @click="copyToClipboard(`${currentUrl}?id_organisme=${organismeActuel.id}`)"
        >
          <template #right-icon>
            <UilShareAlt />
          </template>
        </ButtonClassic>
      </ButtonGroup>
    </template>

    <template
      v-if="product"
      #content
    >
      <template v-if="product.produit_remplacement">
        <div class="grid-zone">
          <div id="zone_produit_non_dispo">
            <Toast
              :title="$t('produit.produit-remplace-par-nouvelle-version')"
              :content="$t('produit.invitons-consulter-remplacement')"
              variant="warning"
              hide-close-btn
            />
            <ProductItem
              :product="product.produit_remplacement"
              view="list"
            />
          </div>
        </div>
        <hr />
      </template>
      <LivrePapierOverview
        :product="product"
        @add-to-liste="checkToList"
      />
      <ProductDescription :product="product" />
      <ProductRelations
        :products="similarProducts"
        title=""
        :is-loading="similarProductsAreLoading"
      />
      <ModalListesSelection
        id="modal_listes_selection"
        :listes="activeLists"
        @open-creation-liste="$modal.show('modal_listes_creation')"
      />
    </template>
    <template
      v-else
      #content
    >
      <div>
        <InfiniteLoader />
      </div>
    </template>
  </PageContent>
</template>

<script>
import {
  ButtonClassic,
  ButtonGroup,
  PageContent,
  Toast,
  InfiniteLoader,
} from "@lde/core_lde_vue";

import IconImage from "@/components/icons/IconImage.vue";
import IconNumeriqueAlt from "@/components/icons/IconNumeriqueAlt.vue";
import { UilShareAlt } from "@iconscout/vue-unicons";

import Badge from "@/components/Badge.vue";
import LivrePapierOverview from "@/components/livres_papier/LivrePapierOverview.vue";
import ProductItem from "@/components/products/ProductItem.vue";
import ProductDescription from "@/components/products/ProductDescription.vue";
import ProductRelations from "@/components/products/ProductRelations.vue";
import ModalListesSelection from "@/components/modals/ModalListesSelection.vue";

import mixinFicheProduit from "@/mixins/mixinFicheProduit";
import mixinDisponibilite from "@/mixins/mixinDisponibilite";

import Api from "@/modules/axios";

import { mapGetters } from "vuex";

/**
 * Vue d'un livre ou manuel papier en particulier avec toutes ses informations.
 */
export default {
  name: "FicheLivrePapier",
  components: {
    PageContent,
    ButtonGroup,
    ButtonClassic,
    Toast,
    Badge,
    ProductItem,
    LivrePapierOverview,
    ProductDescription,
    ProductRelations,
    ModalListesSelection,
    InfiniteLoader,
    IconNumeriqueAlt,
    UilShareAlt,
  },
  mixins: [mixinFicheProduit, mixinDisponibilite],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevPage = from;
    });
  },
  data() {
    return {
      navTitles: [
        { icon: IconImage, anchorId: "livre_papier_overview" },
        { label: this.$t("produit.description"), anchorId: "product_description" },
      ],
      offers: [],
      prevPage: null,
      currentUrl: window.location.href,
      idOrganisme: null,
      similarProducts: [],
      similarProductsAreLoading: false,
    };
  },
  computed: {
    ...mapGetters(["organismeActuel", "isGE"]),
    isCommandeClosed() {
      return this.product.offres?.some((offre) => !offre.catalogue_actif);
    },
  },
  methods: {
    /**
     * Récupère les informations du produit.
     * @returns {Promise} Infos du produit.
     */
    fetchProduct() {
      return Api().get(`/papier/${this.$route.params.id}/`)
        .then(({ data }) => {
          this.product = data;

          if (this.product.offre_defaut) {
            this.product.offre_defaut.quantite = this.product.offre_defaut.quantite_min || 1;
          }

          if (this.$route.hash) {
            setTimeout(() => { window.location.href = this.$route.hash; }, 1);
          }

          this.fetchSimilarArticles();
        });
    },
    /**
     * Récupère les articles de même collection et ceux de même éditeur.
     * @returns {Promise} Produits similaires.
     */
    fetchSimilarArticles() {
      this.similarProductsAreLoading = true;
      return Api().get(`/papier/${this.$route.params.id}/related/`)
        .then((res) => {
          this.similarProducts = res.data;
          this.similarProductsAreLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/fiche_produit.scss";
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;
@use "~@lde/core_lde_vue/dist/assets/styles/_fonts.scss" as font;

.fiche-livre-papier {
  #header_badge{
    padding: var.$space-xx-tiny var.$space-x-tiny;
    @include font.text-medium;
  }

  #zone_produit_non_dispo {
    grid-column: 2 / -2;
    .product-item { margin-top: var.$space-small; }
  }

  hr {
    margin: var.$space-x-large 0 var.$space-large 0;
    border: 1px solid var.$warning;
  }
}
</style>
